.operatorView{
    width:400px;
    height:730px;
    .p-component{
        font-size:12pt;
    }
}

.operatorView_time{
    border:0px solid silver ;
    margin-bottom:20px;
    font-size:20pt;
    font-weight:bold;
}

.operatorView_evd{
    height:80px;
    width:100%;
    border:1px solid silver ;
    margin-bottom:20px;
}

.operatorView_evd_content{
    width:300px;
}

.operatorView_content{
    background: none;

    color: white; 
	border: 1px solid silver !important;
    padding:5px !important;

    .summary{
        background-color: rgba(0,0,0,0.2);
        width:100%;
        padding:5px !important;
    }

    .qualifications{
        width:100%;
        padding:2px !important;
        background-color: rgba(0,0,0,0.0);
        border-left-width: 1px !important; 
        border-color: silver !important;
    }

}
.availablePreview{
    padding:0px !important;  

    border-width: 1px !important; 
    border-color: red;

    .dataHeaderHour{
        background-color:  rgba(0, 0, 0, 0.2) !important;
        width:100px;        
		.weekend{
			background-color: rgba(255,255,255,0.2) !important;
		}
    }
    table, th , tr, td{
        background:none !important;
        border:1px solid silver !important;
    }

    .dataHeaderDate{
        background-color:  rgba(0, 0, 0, 0.2) !important;
        width:50px;
        text-align: center;
    }

    .dataCellHour{
        background-color: rgba(0, 0, 0, 0.2);
    }
    .dataCellOK{
        background-color: rgba(6, 138, 83, 0.2);
        background:none !important;
        border-width:0px 1px;
        border-color:silver;
        width:50%;
    }
    .dataCellWarning{
        //background-color: rgba(230,222,0,0.6);
		color: #ecec00;
        width:50%;
    }
    .dataCellAlarm{
        //background-color: rgba(194, 2, 2, 0.6);
		color: #ec3800;
        width:50%;
    }
    .dataCellDelimiter{
        border-width:0px 1px;
        border-style: solid;
        border-color:rgba(200,200,200,0.4);
        width:1px;
    }
    .weekend{
        background-color: rgba(255,255,255,0.2) !important;
    }
}

#dialogAvailablePreview{
    #dialogAvailablePreview_content{
        background-color: rgba(2,33,55,.9) !important;
    }
    .p-dialog-header{
        background-color: rgba(2,33,55,1) !important;
    }

	.confirmPopupAccept{
		display:none;
	}
	.confirmPopupReject{
		display:none;
	}
}

.dialogTableMatrixHeadline{
	text-align:center;
	padding:2px 5px;
	font-weight:bold;
	font-size:14pt;
}

.dialogTableMatrix{

    th{
        background-color: rgba(1, 1, 94, 0);
    }
    td{
        background-color: rgba(255, 255, 255,0.8);
        color:black;
        padding:2px 5px;
        vertical-align: middle;
        text-align:center;
    }
	.table-header-person-list{
		cursor: pointer;
	}
	.table-header-person-list:hover{
		background-color: #085083;
		color: #fff;
		cursor: pointer;
	}
    .qualificationFunction_red{
        background-color: #ec3800;
        color:white;
    }
	.qualificationFunction_yellow{
        background-color: #ecec00;
        color:black;
    }
}

.operatorView_content_corona{
    color: white; 
    border-width: 1px !important; 
    padding:0px !important;
    border-color: silver !important;


    .summary{
        background-color: rgba(0,0,0,0.2);
        width:100%;
        padding:5px !important;
    }
    .summaryOKO{
        background-color: rgba(0,0,0,0.4);
        width:100%;
        padding:5px !important;
    }

    .qualifications{
        width:100%;
        padding:2px !important;
        background-color: rgba(0,0,0,0.0);
        border-left-width: 1px !important; 
        border-color: silver !important;
    }

}

.operatorView_header{
    background: none !important;
	border: 1px solid silver !important;
    font-weight:200 !important;
    padding:5px 2px !important;
}

.operatorView-datatable {
    .p-datatable {
        background: none !important;
        color: rgba(255, 255, 255, 1) !important;
        .p-datatable-header{
            background-color: rgba(0,0,0,0.4);
            border:1px solid silver !important;
        }
        .p-datatable-tbody{
            tr{
                background: none !important;
            }
        } 
        .p-datatable-thead{
                th{
                    background-color: rgba(0,0,0,0.4);
                }
        } 
    }   
}