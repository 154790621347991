.employee-status{
    width:100%;
    img {
        max-width: 200px;
        max-height: 200px;
    }

    .employee-status-card{
        background-color:rgba(0, 0, 0, 0.2) !important;
        width:330px;
        padding:0.5rem;
        .p-card-content{
            padding: 0px;
        }
        .employee-status-image{
            padding:1.5rem;
        }
        .p-selectbutton .p-button{
            background: #545b62;
        }
        .selectbutton-true{
            .p-button.p-highlight {
                font-weight:bold;
                background: none;
                background-image: none;
                border-color: none;
                color: #fff;
                background-image: linear-gradient(to top, rgba(39,158,13,.5) 10%, rgba(52,207,17,.5) 84%);
            }
        }
        .selectbutton-false{
            .p-button.p-highlight {
                font-weight:bold;
                background: none;
                background-image: none;
                border-color: none;
                color: #fff;
                background-image: linear-gradient(to top, rgba(184,0,0,.5) 10%, rgba(255,0,0,.5) 84%);
            }
        }
    }
    .employee-status-terminal-buttons{
        font-size: 12px;
        margin:0px;
        padding:5px;
    }
    .p-card-body{
        margin:2px;
        padding:2px;  
    }

    @media screen and (max-width: 600px) {
        .employee-status-terminal-buttons{
            display:none;
        }

    }
}