.fc-toolbar-title{
    font-size: 1.5em;
}

.fc .fc-daygrid-week-number{
    font-size: 0.75em;
    color:white;
}
.fullcalendar-employee{
    width:100%;
    padding:10px;
    background: rgba(0,0,0,.2);
    border-radius: 4px;

    @media only screen and (min-width:320px) and (max-width:480px)  {
        padding:5px;
        background: none !important;
        box-shadow: none !important;
    }
}


.fullcalendar{
    width:100%;
    max-width:1200px;
    .fc-toolbar-title{
        padding: 0 23px;
		font-size: 1.5em;
    }

    .fc-button {
    color: #fff !important;
    background: rgba(2, 33, 55, 0.6) !important;
    border: 1px solid rgba(255,255,255,0.3) !important;
    }
    

    .fc-button:enabled:hover {
        background: rgb(9, 34, 52) !important;
        color: #fff !important;
        border-color: rgba(255,255,255,0.5) !important;
    }
    

    .fc-button.fc-dayGridMonth-button.fc-button-active, .fc-button.fc-timeGridDay-button.fc-button-active, .fc-button.fc-timeGridWeek-button.fc-button-active {
        background: rgb(2, 33, 55) !important;
        border-color: rgb(2, 33, 55) !important;
    }
    .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc-button.fc-timeGridDay-button.fc-button-active:hover,.fc-button.fc-timeGridWeek-button.fc-button-active:hover {
        background: rgba(2, 33, 55, 0.8) !important;
        border-color: #4e555b !important;
        color: #fff !important;
    }
    .fc-button.fc-dayGridMonth-button, .fc-button.fc-timeGridDay-button,  .fc-button.fc-timeGridWeek-button {
        background: rgba(2, 33, 55, 0.4) !important;
        border: 1px solid rgba(2, 33, 55, 0.4) !important;
    }
    .fc-liquid-hack .fc-timegrid-axis-frame-liquid {
        position: relative !important;
    }
    .fc-timegrid-axis-cushion, .fc-col-header-cell-cushion {
        overflow:none;
    }
    @media screen and  (min-width:480px) and (max-width: 555px) {
        .fc-toolbar-chunk{
            display:block;
        }
        .fc-toolbar{
            display:block;
        }
        .fc-button{
            padding :5px 10px;
        }
        .fc-toolbar.fc-header-toolbar{
            margin-top:10px;
            margin-bottom:10px;
        }
        .fc-toolbar-title{
            margin:5px 0;
        }
    }
    @media only screen and (min-width:481px) and (max-width: 768px) {
        .fc-toolbar-title{
            font-size:12pt;
        }
    }

    @media only screen and (min-width:320px) and (max-width:480px)  {
        min-width:400px;
        padding:5px;
        margin:0px;
        .fc-view-harness {
            margin-left: -10px !important;
        }
        .fc-toolbar-title{
            margin:5px 0;
			font-size:12pt;
        }
        .fc-toolbar.fc-header-toolbar{
            margin-top:10px;
            margin-bottom:10px;
        }
        .fc-timegrid-slot-label-cushion{
            font-size:10pt;
        }
        .fc-timegrid-axis-cushion, .fc-col-header-cell-cushion {
            font-size:10pt;
            font-weight: 400;
        }
        .fc-toolbar-chunk{
            display:block;
        }
        .fc-toolbar{
            display:block;
        }
        .fc-button{
            padding :5px 10px;
        }
    }
}
