// global override
:root {
	--top-selected-color: #532405;
}

.operationreport {
	height: 500px;
	width: 1000px;
	overflow: auto;
}

.operationreport_othereports {
	height: 200px;
	width: 1000px;
	overflow: auto;
}

.react-datepicker-popper {
	z-index: 100 !important;
}

.react-datepicker__input-container {

	input {
		background: none;
		color: white;
		font-size: 24pt;
		width: 200px;
		border: 0px solid black;
	}
}

#operationreportdialog {
	.p-dialog-title {
		width: 100%;
	}
}

.operationreportdialog_datepicker_donebutton {
	background-color: rgb(100, 100, 100);
	text-align: center;
	padding: 10px 0;
}

#operationreportdialog_captureActivityList {
	.p-tree {
		padding: 0px;
	}

	.p-tree .p-tree-container .p-treenode .p-treenode-content {
		padding: 0px;
		height: 2rem;
	}

}

#operationreportdialog_panel {

	td {
		padding: 5px 0;
	}

	tr {
		color: silver;
	}

	tr.p-highlight {
		color: white !important;
	}



	.p-col {
		padding: 0px;
	}

	.operationreportdialog_headersave {
		width: 160px;

		.p-button {
			width: 160px;
			padding: 5px 5px;
		}
	}

	.operationreportdialog_datepicker {
		margin: 0 0 5px -70px;
		width: 160px;

		.p-button {
			width: 160px;
			padding: 2px 5px;
		}

		button div {
			margin-left: 4px;
		}

		;

	}

	.operationreport_column_selection {
		width: 30px;
		padding-left: 0px;
	}

	.operationreport_column_name {
		width: 200px;
		padding-left: 10px;
	}

	.operationreport_column_bar {
		background-color: rgb(255, 255, 255);
		color: black;
		min-width: 50px;
	}

	.operationreport_column_placeholder {
		background-color: rgb(255, 255, 255);
		color: white;
		width: 100px;
		padding: 0px;
	}

	.operationreport_column_time {
		width: 100px;
		margin: 0 5px;
		padding: 0px;
	}

	.operationreport_column {
		margin: 0px;
		width: 100%;
		padding: 0px;

	}

	.operationreport_column_middle {
		width: 400px;

	}

	.operationreport_column_before {
		width: 150px;

	}

	.operationreport_column_after {
		width: 150px;
	}
}